import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-499787c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fruits-gold-scoreboard" }
const _hoisted_2 = {
  key: 0,
  class: "fruits-gold-scoreboard__carpet-nice"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FruitsGoldCell = _resolveComponent("FruitsGoldCell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreboard, (data, idx) => {
      return (_openBlock(), _createBlock(_component_FruitsGoldCell, {
        digit: data,
        animation: _ctx.isWon,
        glow: _ctx.isGlow(idx)
      }, null, 8, ["digit", "animation", "glow"]))
    }), 256)),
    (_ctx.showAnimation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock("img", {
            key: _ctx.showAnimation,
            ref: "cloudsImage",
            src: _ctx.cloudsOpen,
            alt: "clouds",
            class: "fruits-gold-scoreboard__item"
          }, null, 8, _hoisted_3))
        ]))
      : _createCommentVNode("", true)
  ]))
}